import React from "react";

const VideoIcon = () => {
  return (
    <>
      <span className={"mybasketOnImg myVideoOnImg"}>
        <i className="fa-solid fa-video" style={{ color: "#ffffff" }}></i>
      </span>
    </>
  );
};

export default VideoIcon;
