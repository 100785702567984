import React from 'react';
import "./load.css";

const Load = () => {
  return (
    <div class="loader"></div> 

  );
};

export { Load };
