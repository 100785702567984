import React from "react";
import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = sessionStorage.getItem("Resp");
  if (token) {
    
  }else{
    return <Navigate to="/HomeLogin" />;
  }

  return children;
};

export default ProtectedRoute;
