import React from 'react'

const HomeOrFlat = ({HomeOrFlat}) => {
  return (
    <>
     <div className="col-12 col-lg-3">
                <div className="d-flex flex-row justify-content-between p-2 MakeHandSelect">
                  <span>Kirayə yoxsa satılıq:</span>
                  <span>
                    <i className="fa-solid fa-chevron-down"></i>
                  </span>
                </div>

                <div className="mt-3 col-12">
                  <div className="col-12 div-in-select chechAndLabel ms-1">
                    <div>
                      <input
                        type="checkbox"
                        name=""
                        id="Satılır"
                        ref={(element) => (HomeOrFlat.current[1] = element)}
                      />
                      <label className="ms-1">Satılıq</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name=""
                        id="Kiraye verilir"
                        ref={(element) => (HomeOrFlat.current[2] = element)}
                      />
                      <label className="ms-1">Kirayə</label>
                    </div>
                  </div>
                </div>
              </div>
    </>
  )
}

export default HomeOrFlat